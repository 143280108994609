<template>
  <div>
    <editor-tinymce v-model="text" :init="options" style="height: 450px;" />
  </div>
</template>

<script>
import EditorTinymce from '@tinymce/tinymce-vue'
export default {

  components: {
    EditorTinymce
  },
  props: [
    'value',
    'textOnly'
  ],

  data () {
    let toolbar = 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | code'
    if (!this.textOnly) toolbar += '| link image uploadcare'

    return {
      text: null,
      buffer: null,
      options: {
        relative_urls: false,
        content_style: "p {padding: 0 !important;margin: 2px 0 !important}",
        oninit: 'setPlainText',
        plugins: ['code', 'uploadcare', 'image', 'link', 'paste'],
        toolbar,
        convert_urls: false,
        init_instance_callback: (editor) => {
          editor.on('blur', () => this.$emit('blur'))
          editor.on('blur', () => this.$emit('blur'))
        }
      }
    }
  },

  watch: {
    value () {
      if (this.value !== this.text) this.text = this.value
    },
    text () {
      this.$emit('input', this.text)
    }
  },

  created () {
    this.text = this.value
  }
}
</script>
